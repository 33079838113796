.fs-18 {
    size: 18px;

}

.fs-14 {
    color: #454549;
    size: 14px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.spinning {
    animation: spin 1s linear infinite;
}

/* .modal-backdrop {
    position: 'fixed';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

}

/* 
.modal-content {
    background-color: white;
    Padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */