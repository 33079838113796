.orientation.form-check-input,
.layout.form-check-input {
    position: relative;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #ced4da;
    border-radius: 50%;
    cursor: pointer;
}

.orientation.form-check-input:checked,
.layout.form-check-input:checked {
    background-color: white;
    border: 2px solid black;
}

.orientation.form-check-input:checked::before,
.layout.form-check-input:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    background-color: black;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}

.orientation.form-check-label,
.layout.form-check-label {
    color: black;
    margin-left: 10px;

}

.playlist input[type=checkbox] {
    position: absolute;
    top: 15px;
    right: 22px;
    width: 18px;
    height: 18px;
    -webkit-appearance: none;

    -moz-appearance: none;
    appearance: none;
    background-color: transparent;

    border: 1px solid transparent;

}

.playlist input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid black;

    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.08);

}

.playlist input[type=checkbox]:checked {
    background-color: black;

}

.playlist input[type=checkbox]:checked:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;

    background-color: transparent;

}

.playlist input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 9px;
    color: black;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 5px;
}

.video input[type=checkbox] {
    position: absolute;
    top: 15px;
    right: 22px;
    width: 18px;
    height: 18px;
    -webkit-appearance: none;

    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border-radius: 0px !important;
    border: 1px solid transparent;

}

.video input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid black;
    background-color: rgba(255, 255, 255, 0.08);

}

.video input[type=checkbox]:checked {
    background-color: rgba(238, 238, 119, 1);

}

.video input[type=checkbox]:checked:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;

    background-color: transparent;

}

.video input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 5px;
}

.edit {
    position: absolute;
    width: 100%;
    height: 50vh;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}