.tags {
    width: "32%";
    font-size: 10px;
    margin-top: -9%;
}

.tags1 {
    width: "32%";
    font-size: 10px;
    margin-top: -9%;
}

.buynow-button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.overlay {
    position: absolute;
    width: 100vh;
    height: 74vh;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}


@media(max-width:1390px) {

    .tags,
    .tags1 {
        width: 85%;
        justify-content: center;
        align-items: center;
        margin-right: 2.5%;
        margin-top: -11%;

    }

}

@media(max-width:1200) {

    .tags,
    .tags1 {
        width: 80%;
        justify-content: center;
        align-items: center;
        bottom: 109px;
        margin-right: 2.5%;
        margin-top: -12%;

    }
}