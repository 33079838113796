@font-face {
  font-family: 'CircularStd';
  src: url('../src/assets/CircularStd/CircularStd-Black.otf') format('opentype');
  /* font-weight: 900; */
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd';
  src: url('../src/assets/CircularStd/CircularStd-BlackItalic.otf') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: 'CircularStd';
  src: url('../src/assets/CircularStd/CircularStd-Bold.otf') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd';
  src: url('../src/assets/CircularStd/CircularStd-BoldItalic.otf') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: 'CircularStd';
  src: url('../src/assets/CircularStd/CircularStd-Book.otf') format('opentype');
  font-style: normal;
}


@font-face {
  font-family: 'CircularStd';
  src: url('../src/assets/CircularStd/CircularStd-BookItalic.otf') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: 'CircularStd';
  src: url('../src/assets/CircularStd/CircularStd-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd';
  src: url('../src/assets/CircularStd/CircularStd-Light\ Italic.otf') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: 'CircularStd';
  src: url('../src/assets/CircularStd/CircularStd-Medium.otf') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd';
  src: url('../src/assets/CircularStd/CircularStd-MediumItalic.otf') format('opentype');
  font-style: italic;
}

/* @import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';
@import 'react-confirm-alert/src/react-confirm-alert.css'; */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "CircularStd";
}

.bg_black {
  background-color: #17171C !important;
}

.form-select {
  color: hsl(0, 0%, 40%) !important;
}

.text_black {
  color: #17171C !important;
}

.text-dark {
  color: #17171C !important;
}

.loader_comp_main_div {
  display: grid;
  place-items: center;
  height: 100%;
}

.video-modal-sr-pb-pi-pdetails-ssv13 {
  width: 100%;
  height: 100%;
  margin: 10px 0px;
  overflow-y: scroll;
}

::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 7px;
  border-radius: 10px;
  height: 7px;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}


/* .Toastify__progress-bar-theme--dark {
  background-color: rgb(238, 238, 119) !important;
} */

/* .Toastify__toast--warning .Toastify__toast-icon svg { */
/* fill: rgb(238, 238, 119) !important; */
/* Change this to your desired icon color */
/* } */

/* .Toastify__toast-theme--dark {
  background-color: #fff;
} */

/* .Toastify__progress-bar {
  opacity: 1;
} */