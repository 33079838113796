/* .switch-lg {
    width: 80px;
    height: 40px;
}

.switch-lg:checked {
    background-color: #4CAF50;
}

.switch-lg:checked::before {
    transform: translateX(40px);
} */



.product_title_preview {
    width: calc(100% - 23%);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

}

.product {
    font-size: 32px;
    font-weight: 700;
}