.sidebar {
    background-color: #EEEE77;
    width: 300px;
    height: 100vh;
}

.bg_yellow {
    background-color: #EEEE77;
}

.text_yellow {
    color: #EEEE77 !important;
    font-weight: 300 !important;
}

.topbar_main_div {
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid rgba(23, 23, 23, 0.15);
    /* Adjust as needed */
    color: #fff;
    /* Adjust text color as needed */
    z-index: 1000;
    height: 60px;
    /* Ensure it appears above other content */
    /* Add any other styles you need */
}

.image_profile_user {
    cursor: pointer;
}

.nav-link.active {
    /* Your CSS styles here */
    /* For example: */
    background-color: #fff !important;
    border-radius: 0;
    border: none !important;
    border-bottom: 2px solid #17171C !important;
    color: white;
}

.nav-link {
    border: none !important;
    border-radius: 0 !important;
    color: #17171C;
}

@media only screen and (max-width: 576px) {
    .logo_swirl {
        display: none !important;
    }

    .logo_swirl_small {
        display: block !important;
    }
}