.fs-16 {
  font-size: 16px;
  font-weight: lighter;
  color: #747477;
  width: 82%
}

.fs-18 {
  font-size: 18px;
}

.fs-24 {
  border-bottom: 1px solid #E8E8E8;
  color: #454549;
  font-size: 24px;
}

.switch-lg {
  width: 43px !important;
  height: 24px;
  appearance: none;
  margin-right: 5px;
}

.switch-lg:checked {
  background-color: #4757A3;

}

.switch-lg:checked::before {
  transform: translateX(40px);
}

.fs-14 {
  font-size: 14px;
  color: #454549;
}

.css-qbdosj-Input {
  width: 51vh;
}

.title_video_select {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card {
  --bs-card-spacer-y: 0px;
  --bs-card-spacer-x: 0px;
}

img,
svg {
  vertical-align: text-bottom;
}

.btn_quantity1 {
  padding: 6px 15px;
}

.btn_quantity2 {
  padding: 6px 13px;
}

/* .card-body-add {
  border-radius: 8px;
  margin-top: -90%;
  background: white;
  width: auto;
  margin-left: 8px;
  margin-right: 8px;
  z-index: 2;
  position: relative;
  overflow: hidden;
} */

.card-title-add h5 {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card-button-add {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  /* Ensure buttons take the full width of their container */
}

.tag {
  bottom: 110px;
  width: 31%;
  font-size: 10px;
  color: white !important;
  font-weight: lighter;
  display: flex;
  align-items: center;
  padding: 6px;
  padding-left: 8px;
}

.button-video {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  cursor: pointer;
}



@media (max-width: 576px) {
  .card-body-add {
    margin-top: 10px;
  }

  .card-button-add {
    width: 100%;
    margin-bottom: 5px;
  }


}

@media (max-width:1700px) {
  .prices {
    font-size: 12px;
  }


}

@media(max-width:1390px) {
  .prices {
    font-size: 9px;
  }

  .tag {
    width: 94%;
    justify-content: center;
    align-items: center;
    bottom: 109px;
    margin-right: 2.5%;

  }

}