.step-number {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background-color: white;
  color: #747477;
  text-align: center;
  margin-bottom: 5px;
  margin: 1em;
  border: 1px solid #D1D1D2;
}

.bg-eeee77 {
  background-color: #EEEE77;
  color: black;
  border: none;
}

/* .row>* {
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;
      padding-right: 0 !important;
      padding-left: 0 !important;
      margin-top: var(--bs-gutter-y);
  } */
.title_video_select {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.coly_field {
  font-family: monospace;
  background-color: #f8f9fa;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-x: scroll;
  white-space: nowrap;
  resize: none;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);

  }

  100% {
    transform: translateY(0);
  }
}

.bounce {
  animation: bounce 0.5s;

}